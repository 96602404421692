
import { computed, defineComponent } from "vue";
import store from "@/store";
import { LoadEntity } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Section",
  components: {},
  setup() {

    const sections = computed(() => store.state.PageModule.sections)
    const selectedDoc = computed(() => store.state.PageModule.selectedDoc);
    const doc = computed(() => store.state.PageModule.pageEntity.data);
    return {
      sections,
      doc,
      selectedDoc,
      ...LoadEntity(Actions.LOAD_SECTIONS)
    }
  },
  methods: {
    onSelect(data) {
      // this.isSelected = true;

      store.commit(Mutations.SET_SELECTED_DOC, data);

    },

  }
})
