
import { computed, defineComponent, watch } from "vue";
import EntityLoading from "../../components/base/loading/EntityLoading.vue";
import store from "../../store";
import { LoadEntity } from "@/core/composite/composite";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Avatar from "@/components/base/common/Avatar.vue";
import Row from "@/components/base/common/Row.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import Section from "@/views/page/Section.vue";
import File from "@/views/page/File.vue";

export default defineComponent({
  name: "Page",
  components: {File, Section, Row, Avatar, EntityLoading},
  props: {
    id: {type: String, required: true}
  },
  setup() {
    const pageEntity = computed(() => store.state.PageModule.pageEntity)
    const selectedDoc = computed(()=>store.state.PageModule.selectedDoc);
    watch(() => store.state.PageModule.pageEntity, (cb:any) => {
      let pageTitle = 'Help Center';
      const data: any = []
      if (cb.data.collection) {
        data.push({link: true, router: '/help/doc/collection/' + cb.data.collection.id, text: 'Help Center'})
      }
      if (cb.data.folder) {
        data.push({link: true, router: '/help/doc/folder/' + cb.data.folder.id, text: cb.data.folder.name})
      }
      data.push({link: false, router: '', text: cb.data.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })
    return {
      ...LoadEntity(Actions.LOAD_PAGE),
      pageEntity,
      selectedDoc,
    }
  },
  methods:{
    onSelect(data) {
      // this.isSelected = true;
      store.commit(Mutations.SET_SELECTED_DOC, data);
    },
  },
  watch: {

    id(objectId) {
      this.reloadDataWithId(objectId);
    }
  }
})
