
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { LoadEntity } from "@/core/composite/composite";
import Swal from "sweetalert2";
import { makeDownloadLink } from "@/core/helpers/functions";
import QuickAction from "@/components/base/action/QuickAction.vue";
import PageService from "@/core/services/PageService";

export default defineComponent({
  name: "File",
  components: {QuickAction, DateTimeFormat, Avatar},
  setup() {
    const files = computed(() => store.state.PageModule.files);
    const doc = computed(() => store.state.PageModule.pageEntity.data);
    const state = ref({
      show: false,
      submitting: false,
      model: {file: '', parentId: ''}
    })
    return {
      files,
      doc,
      state,
      ...LoadEntity(Actions.LOAD_FILES)
    }
  },
  methods: {
    onDownload(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PageService.download(id).then(res => {
        makeDownloadLink(res)
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        Swal.close();
      })
    }
  }
})

